<template>
  <div class="zebra-table" :class="{ 'first-col-hover': firstColHandleClick }">
    <div class="zebra-table__header">
      <div
        class="zebra-table__row"
        :style="`grid-template-columns:${gridColumns};`"
      >
        <div
          class="zebra-table__col _header"
          v-for="headerElement in headerElements"
          :key="headerElement"
        >
          {{ headerElement }}
        </div>
      </div>
    </div>
    <div class="zebra-table__rows">
      <div
        class="zebra-table__row"
        :id="idFieldName + `_` + element[idFieldName]"
        :style="`grid-template-columns:${gridColumns};`"
        :key="rowIndex"
        v-for="(element, rowIndex) in elements"
      >
        <div
          class="zebra-table__col"
          v-for="(elementInfo, colIndex) in elementsOrder"
          :key="elementInfo"
          v-html="getElementValue(element, elementInfo)"
          @click="handleClick(element, colIndex)"
          :title="titles[colIndex] || null"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { defineEmits } from "vue";

type TypeProps = {
  headerElements: string[];
  elements: any[];
  gridColumns: string;
  elementsOrder: string[];
  idFieldName?: string;
  firstColHandleClick?: boolean;
  titles?:string[]
};

const props = withDefaults(defineProps<TypeProps>(), {
  isHandleClick: false,
});

const emit = defineEmits(["fisrtElementWasClicked"]);

function handleClick(element, index) {
  if (props.firstColHandleClick && index === 0) {
    emit("fisrtElementWasClicked", element);
  }
}

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}
function getElementValue(element, elementInfo) {
  if (isObject(elementInfo)) {
    const result = elementInfo.func(element[elementInfo.value]);

    if (typeof result === "boolean") {
      return `<div class="${result ? "i-success" : "i-failed"}"></div>`;
    } else {
      return result;
    }
  } else {
    return element[elementInfo];
  }
}
</script>

<style lang="scss" scoped>
.zebra-table {
  &__header {
    background: #f7f9fc;
    font-weight: 500;
  }

  &.first-col-hover &__col:first-child:not(._header):hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }

  &__row {
    display: grid;
    background: #fff;
    grid-gap: 10px;
    
    align-items: center;

    &:nth-child(even) {
      background-color: #f7f6f6;
    }
  }

  &__col {
    transition: .1s;
    padding: 14px 15px;
  }

  &__rows {
  }
}
</style>
