import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "reactivation-table__row _head" }
const _hoisted_2 = { class: "fs-12px" }
const _hoisted_3 = {
  key: 0,
  class: "fs-12px statuses-list"
}
const _hoisted_4 = ["onClick", "innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "reactivation-table__col"
}
const _hoisted_6 = {
  key: 1,
  class: "reactivation-table__col"
}
const _hoisted_7 = {
  key: 2,
  class: "reactivation-table__col"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "reactivation-table__col fs-12" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "reactivation-table__col"
}
const _hoisted_14 = {
  key: 1,
  class: "reactivation-table__col"
}
const _hoisted_15 = {
  key: 2,
  class: "reactivation-table__col"
}
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "reactivation-table__col" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  class: "reactivation-table__err"
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = {
  key: 0,
  class: "small-loader"
}

import { computed, ref } from "vue";
import { useStore } from "vuex";
import reactivationItemPage from "@/components/reactivation/reactivationItemPage.vue";
import getSegmentByIndex from "@/functions/getSegmentByIndex";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationTable',
  emits: ["openActivation"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emit = __emit;

const getReactivationLoading = computed(
  () => store.state.reactivation.getReactivationLoading
);

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

const reactivationStatuses = computed(
  () => store.state.reactivation.reactivationStatuses
);

const reactivationTableErrText = computed(() => {
  if (getReactivationLoading.value) {
    return 'Загрузка'
  } else {
    if (currentReactivationFilterStatus.value.id === null) {
      return 'Реактивации не найдены, чтобы создать реактивацию, передйите в  раздел "сегменты", выберите нужный сегмент, и нажмине на кнопку "Реактивация"'
    } else {
      return 'Реактивации не найдены'
    }
  }
})

const currentReactivationFilterStatus = computed(() => {
  const statusId = store.state.reactivation.currentReactivationFilterStatusId;
  return reactivationStatuses.value.find((i) => i.id === statusId);
});

const isShowFilterStatuses = ref(false);

function setNewReactivationStatus(status_id) {
  store.commit("reactivation/setCurrentReactivationStatus", status_id);
}

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

function getStatus(activation) {
  return activation.goal.description;
}
function getCurrentSegment(activation) {
  const modifySegments = store.state.segments.modifySegments;
  const segmentIndex = activation.segment_id
  return getSegmentByIndex(modifySegments, segmentIndex).name
}
function formatNumber(value) {
  return Math.trunc(value).toString();
}

let currentActivation = ref(null);

function closeReactivationItem() {
  currentActivation.value = null;
}

function getClientsInfo(activation, countKey, percentKey) {
  if (typeof activation.goal[countKey] === "number") {
    const countValue = formatNumber(activation.goal[countKey]);
    const percentValue = activation.goal[percentKey]
      ? formatNumber(activation.goal[percentKey]) + "%"
      : "0%";
    return `<span>${countValue}</span> / <span>${percentValue}</span>`;
  } else {
    return "- / -";
  }
}

function getPluralForm(number, forms) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1];
  }
  if (n1 === 1) {
    return forms[0];
  }
  return forms[2];
}

function getRevenue(activation) {
  const revenue = activation.goal.revenue
    ? activation.goal.revenue.toLocaleString() + " ₽"
    : "0";

  const revenueLoyalty = activation.goal.revenue_loyalty
    ? activation.goal.revenue_loyalty.toLocaleString() + " ₽"
    : "0";

  return `${revenueLoyalty} | ${revenue}`;
}
function getAvarageCheck(activation) {
  return activation.goal.average_check
    ? activation.goal.average_check.toLocaleString() + " ₽"
    : "-";
}

function getIsShowParams(activation) {
  return [1, 2, -1].includes(activation.goal.is_active);
}

function getOrderClients(activation) {
  return getClientsInfo(
    activation,
    "signed_up_clients_count",
    "signed_up_clients_percent"
  );
}

function getCameClients(activation) {
  return getClientsInfo(
    activation,
    "came_clients_count",
    "came_clients_percent"
  );
}

function getPaidClient(activation) {
  return getClientsInfo(
    activation,
    "paid_clients_count",
    "paid_clients_percent"
  );
}

function getChannel(activation) {
  return activation.channel[0] === "SMS" ? "WhatsApp" : "Email";
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reactivation-table", isShowWhatsApp.value ? '__state1' : '__state2'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "reactivation-table__col" }, "ID", -1)),
      _createElementVNode("button", {
        class: _normalizeClass(["reactivation-table__col _status", { _open: isShowFilterStatuses.value }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isShowFilterStatuses.value = !isShowFilterStatuses.value))
      }, [
        _cache[1] || (_cache[1] = _createTextVNode(" Статус ")),
        _createElementVNode("div", _hoisted_2, _toDisplayString(currentReactivationFilterStatus.value.description), 1),
        (isShowFilterStatuses.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reactivationStatuses.value, (status) => {
                return (_openBlock(), _createElementBlock("button", {
                  class: "statuses-list__item",
                  onClick: ($event: any) => (setNewReactivationStatus(status.id)),
                  key: status.id,
                  innerHTML: status.description
                }, null, 8, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "reactivation-table__col" }, "Название", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "reactivation-table__col" }, "Сегмент", -1)),
      (isShowWhatsApp.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Отправлено "))
        : _createCommentVNode("", true),
      (isShowWhatsApp.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Доставлено "))
        : _createCommentVNode("", true),
      (isShowWhatsApp.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Прочитано"))
        : _createCommentVNode("", true),
      _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"reactivation-table__col\" data-v-5fa499b4>Записался</div><div class=\"reactivation-table__col\" data-v-5fa499b4>Пришел</div><div class=\"reactivation-table__col\" data-v-5fa499b4>Оплатил</div><div class=\"reactivation-table__col\" data-v-5fa499b4>Средний чек</div><div class=\"reactivation-table__col _revenue\" data-v-5fa499b4> Выручка <div class=\"fs-10px\" data-v-5fa499b4>Бонусы | Рубли</div></div><div class=\"reactivation-table__col\" data-v-5fa499b4></div>", 6))
    ]),
    (reactivationList.value && reactivationList.value.find((i) => i.goal))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reactivationList.value, (activation) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "reactivation-table__row",
              key: activation.id
            }, [
              _createElementVNode("div", _hoisted_9, "#" + _toDisplayString(activation.id), 1),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getStatus(activation)
              }, null, 8, _hoisted_10),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: activation.name
              }, null, 8, _hoisted_11),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getCurrentSegment(activation)
              }, null, 8, _hoisted_12),
              (isShowWhatsApp.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, "-"))
                : _createCommentVNode("", true),
              (isShowWhatsApp.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, "-"))
                : _createCommentVNode("", true),
              (isShowWhatsApp.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, "-"))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getOrderClients(activation)
              }, null, 8, _hoisted_16),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getCameClients(activation)
              }, null, 8, _hoisted_17),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getPaidClient(activation)
              }, null, 8, _hoisted_18),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getAvarageCheck(activation)
              }, null, 8, _hoisted_19),
              _createElementVNode("div", {
                class: "reactivation-table__col",
                innerHTML: getRevenue(activation)
              }, null, 8, _hoisted_20),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("button", {
                  class: "reactivation-table__btn",
                  onClick: ($event: any) => (emit('openActivation', activation))
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/images/eye-icon.svg'),
                    alt: ""
                  }, null, 8, _hoisted_23)
                ], 8, _hoisted_22)
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", { innerHTML: reactivationTableErrText.value }, null, 8, _hoisted_25),
          (getReactivationLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26))
            : _createCommentVNode("", true)
        ]))
  ], 2))
}
}

})