<template>
  <div class="awaiting-segments">
    <div class="awaiting-segments__list">
      <div
        @click="emit('setAwaitSegment', segment)"
        class="awaiting-segments__item base-segment"
        :class="{
          _hover: !isTableLoading,
          '_border-top-radius': number === 0,
          '_border-bottom-left-radius': number === 2,
        }"
        v-for="(segment, number) in awaitngSegments"
        :key="segment.index"
        :style="`background-color:${segment.background_color}; height: ${
          (segment.max_frequency - segment.min_frequency) * 20
        }%`"
      >
        <div>{{ segment.name }}</div>
        <div
          class="awaiting-segments__stats fs-18px"
          :class="{ 'loading-text': isTableLoading }"
        >
          <span class="fw-600">
            {{ segment.total_clients }}
          </span>
          —
          {{ segment.total_clients_percent }}%
        </div>
        <div>На сумму</div>
        <div
          class="segments-item__check fw-600 fs-18px"
          :class="{ 'loading-text': isTableLoading }"
        >
          {{ (+segment.check).toLocaleString() }} ₽
        </div>
      </div>
    </div>

    <div class="awaiting-segments__title fw-600">Зона ожидания</div>

    <baseSegment
      class="awaiting-segments__all"
      :class="{ '_hover _border-radius': !isTableLoading }"
      :segment="awaitSegment"
      @click="emit('setAwaitSegment', awaitSegment)"
    >
      <div class="base-segment__name mt-11px">Оперативные записи</div>
      <div
        class="base-segment__stats"
        :class="{ 'loading-text': isTableLoading }"
      >
        <span class="fw-500">В разработке</span>
      </div>
    </baseSegment>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import baseSegment from "@/components/main-page/segments/baseSegment.vue";

const emit = defineEmits(["setAwaitSegment"]);

const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const awaitngSegments = computed(
  () => store.state.segments.modifySegments.awaitingSegments
);

const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

const store = useStore();
</script>

<style lang="scss" scoped>
.awaiting-segments {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid 1px #cccccc;
  border-radius: 15px;
  padding: 5px 5.5px 5px 5px;

  &__title {
    padding-left: 3px;
    padding-bottom: 5px;
    padding-left: 18px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 608px;
  }

  &__item {
    padding-left: 20px !important;

    margin-bottom: 9.9px;

    display: grid;
    grid-template-rows: 1fr 2fr 1fr 2fr;
    row-gap: 10px;

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &:last-child {
      border-bottom-left-radius: 12px;
    }
  }

  &__all {
    border-radius: 12px;
  }
}
</style>
