import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/letter.svg'


const _hoisted_1 = { class: "reactivation-form__block" }
const _hoisted_2 = { class: "reactivation-form__title" }
const _hoisted_3 = { class: "reactivation-select" }
const _hoisted_4 = {
  for: "rv01",
  class: "reactivation-select__item"
}
const _hoisted_5 = {
  for: "rv02",
  class: "reactivation-select__item"
}
const _hoisted_6 = {
  for: "check1",
  class: "reactivation-select__item _ignor"
}
const _hoisted_7 = { class: "reactivation-form__block" }
const _hoisted_8 = { class: "reactivation-select" }
const _hoisted_9 = {
  key: 0,
  for: "rv1",
  class: "reactivation-select__item"
}
const _hoisted_10 = {
  for: "rv2",
  class: "reactivation-select__item"
}
const _hoisted_11 = {
  for: "rv3",
  class: "reactivation-select__item"
}
const _hoisted_12 = { class: "reactivation-form__block" }
const _hoisted_13 = { class: "reactivation-form__subtitle text-small" }
const _hoisted_14 = { class: "reactivation-inputs" }
const _hoisted_15 = { class: "reactivation-inputs__row" }
const _hoisted_16 = { class: "reactivation-inputs__col" }
const _hoisted_17 = ["min"]
const _hoisted_18 = { class: "reactivation-form__block _aims" }
const _hoisted_19 = { class: "reactivation-inputs" }
const _hoisted_20 = { class: "reactivation-inputs__col" }
const _hoisted_21 = { class: "reactivation-select _last" }
const _hoisted_22 = {
  for: "",
  class: "reactivation-select__item _ignor"
}
const _hoisted_23 = { class: "reactivation-form__block _btns" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "reactivation-btn__number" }

import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationCreateForm',
  setup(__props, { emit: __emit }) {

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const currentReactivationClientCountLoading = computed(
  () => store.state.reactivation.currentReactivationClientCountLoading
);
/* eslint-disable-next-line */
const emit = __emit;

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

function isPastOrNearFuture(date, time) {
  const inputDateTime = new Date(`${date}T${time}:00`);
  const now = new Date();
  const fiveMinutesInMillis = 5 * 60 * 1000;

  return (
    inputDateTime <= now ||
    inputDateTime <= new Date(now.getTime() + fiveMinutesInMillis)
  );
}

const currentReactivationClientCount = computed(() => {
  return store.state.reactivation.currentReactivationClientCount;
});
function getCurrentTimePlusSixMinutes() {
  const now = new Date();
  const sixMinutesInMillis = 6 * 60 * 1000;
  const futureTime = new Date(now.getTime() + sixMinutesInMillis);

  // Округляем секунды в большую сторону
  if (futureTime.getSeconds() > 0) {
    futureTime.setMinutes(futureTime.getMinutes() + 1);
  }
  futureTime.setSeconds(0);

  // Форматируем время в "чч:мм"
  const hours = String(futureTime.getHours()).padStart(2, "0");
  const minutes = String(futureTime.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

let errForm = ref(null);

function createMessage() {
  errForm.value = isInvalideForm();
  if (!errForm.value) {
    store.state.reactivation.reactivationCreateStep = 1;
    window.scrollTo(0, 0);
  }
}
function prevStep() {
  emit("prev");

  router.push("/");

  window.scrollTo({
    top: 0,
  });
}

const startDate = computed(
  () => store.state.reactivation.createReactivationFromData.startDate
);
const startTime = computed(
  () => store.state.reactivation.createReactivationFromData.startTime
);

function isInvalideForm() {
  if (!startDate.value) {
    return "Не указана дата начала рассылки";
  }

  if (!startTime.value) {
    return "Не указано время начала рассылки";
  }

  if (isPastOrNearFuture(startDate.value, startTime.value)) {
    const futureTime = getCurrentTimePlusSixMinutes();
    return `Минимальное время - сегодня ${futureTime.toLocaleString()}`;
  }

  return false;
}

const finishAfterNextMailing = computed({
  get: () =>
    store.state.reactivation.createReactivationFromData.finishAfterNextMailing,
  set: (newValue) => {
    store.state.reactivation.createReactivationFromData.finishAfterNextMailing =
      newValue;
  },
});

const trackingDays = computed({
  get: () => store.state.reactivation.createReactivationFromData.trackDuring,
  set: (newValue) => {
    store.state.reactivation.createReactivationFromData.trackDuring = newValue;
  },
});

function validateTrackingDays() {
  trackingDays.value = trackingDays.value.replace(/\D/g, "");
  if (Number(trackingDays.value) > 365) {
    trackingDays.value = "365";
  }
}

function validateTrackingDaysBlur() {
  if (trackingDays.value < 10) {
    trackingDays.value = 10;
  }
}

let currentDate = ref(null);

onMounted(() => {
  const now = new Date();
  const futureDate = new Date(now.getTime() + 10 * 60000); // добавляем 10 минут

  // Форматируем дату в "гггг-мм-дд"
  const year = futureDate.getFullYear();
  const month = String(futureDate.getMonth() + 1).padStart(2, "0");
  const day = String(futureDate.getDate()).padStart(2, "0");
  const futureDateString = `${year}-${month}-${day}`;

  // Форматируем время в "чч:мм"
  const hours = String(futureDate.getHours()).padStart(2, "0");
  const minutes = String(futureDate.getMinutes()).padStart(2, "0");
  const futureTimeString = `${hours}:${minutes}`;

  store.state.reactivation.createReactivationFromData.startDate =
    futureDateString;
  store.state.reactivation.createReactivationFromData.startTime =
    futureTimeString;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reactivation-form", { '--loading': currentReactivationClientCountLoading.value }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, " Получатели из сегмента \"" + _toDisplayString(activeSegment.value.name) + "\" ", 1),
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "reactivation-form__subtitle text-small" }, " Клиент явно дал согласие на отправку информационно-рекламной рассылки ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, [
          _createVNode(inputTypeRadio, {
            modelValue: 
              _unref(store).state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            ,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            ) = $event)),
            id: "rv01",
            name: "radio0",
            value: "1"
          }, null, 8, ["modelValue"]),
          _cache[10] || (_cache[10] = _createElementVNode("span", null, "Исключить клиентов которые уже участвуют в активной Реактивации", -1))
        ]),
        _createElementVNode("label", _hoisted_5, [
          _createVNode(inputTypeRadio, {
            modelValue: 
              _unref(store).state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            ,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            ) = $event)),
            id: "rv02",
            name: "radio0",
            value: "0"
          }, null, 8, ["modelValue"]),
          _cache[11] || (_cache[11] = _createElementVNode("span", null, "Включить в Реактивацию всех клиентов сегмента", -1))
        ]),
        _createElementVNode("label", _hoisted_6, [
          _createVNode(inputCheck, {
            id: "check1",
            modelValue: 
              _unref(store).state.reactivation.createReactivationFromData.ignorePostBan
            ,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData.ignorePostBan
            ) = $event))
          }, null, 8, ["modelValue"]),
          _cache[12] || (_cache[12] = _createElementVNode("span", null, [
            _createTextVNode("Игнорировать "),
            _createElementVNode("span", { style: {"font-weight":"900","font-style":"italic"} }, "несогласие"),
            _createTextVNode(" на отправку")
          ], -1)),
          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-small" }, "Отправлять тем, у кого нет согласий для отправки уведомлений", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "reactivation-form__title" }, "Каналы отправки", -1)),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "reactivation-form__subtitle text-small" }, " WhatsApp или Email ", -1)),
      _createElementVNode("div", _hoisted_8, [
        false
          ? (_openBlock(), _createElementBlock("label", _hoisted_9, [
              _createVNode(inputTypeRadio, {
                modelValue: 
              _unref(store).state.reactivation.createReactivationFromData.channel
            ,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData.channel
            ) = $event)),
                id: "rv1",
                name: "radio1",
                value: "PUSH"
              }, null, 8, ["modelValue"]),
              _cache[15] || (_cache[15] = _createElementVNode("span", null, "Всем у кого есть мобильное приложение YPLACES, отправится PUSH", -1))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("label", _hoisted_10, [
          _createVNode(inputTypeRadio, {
            modelValue: 
              _unref(store).state.reactivation.createReactivationFromData.channel
            ,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData.channel
            ) = $event)),
            id: "rv2",
            name: "radio1",
            value: "SMS"
          }, null, 8, ["modelValue"]),
          _cache[16] || (_cache[16] = _createElementVNode("span", null, "Всем отправить по WhatsApp", -1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(inputTypeRadio, {
            class: "_in-develop",
            modelValue: 
              _unref(store).state.reactivation.createReactivationFromData.channel
            ,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((
              _unref(store).state.reactivation.createReactivationFromData.channel
            ) = $event)),
            id: "rv3",
            name: "radio1",
            value: "email",
            disabled: ""
          }, null, 8, ["modelValue"]),
          _cache[17] || (_cache[17] = _createElementVNode("span", { class: "development-hover" }, "Всем у кого есть Email", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[23] || (_cache[23] = _createElementVNode("div", { class: "reactivation-form__title" }, " Запланировать старт Реактивации ", -1)),
      _withDirectives(_createElementVNode("div", _hoisted_13, " время в часовом поясе Europe/Moscow (UTC+03) ", 512), [
        [_vShow, !_unref(errForm)]
      ]),
      _withDirectives(_createElementVNode("div", { class: "reactivation-form__subtitle text-small text-error" }, _toDisplayString(_unref(errForm)), 513), [
        [_vShow, _unref(errForm)]
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-small" }, "на", -1)),
          _createElementVNode("div", _hoisted_16, [
            _withDirectives(_createElementVNode("input", {
              type: "date",
              min: _unref(currentDate),
              class: _normalizeClass([{ _error: _unref(errForm) }, "default-input"]),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((
                _unref(store).state.reactivation.createReactivationFromData.startDate
              ) = $event))
            }, null, 10, _hoisted_17), [
              [_vModelText, 
                _unref(store).state.reactivation.createReactivationFromData.startDate
              ]
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-small" }, "в", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "time",
              class: _normalizeClass([{ _error: _unref(errForm) }, "default-input"]),
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((
                _unref(store).state.reactivation.createReactivationFromData.startTime
              ) = $event))
            }, null, 2), [
              [_vModelText, 
                _unref(store).state.reactivation.createReactivationFromData.startTime
              ]
            ])
          ])
        ]),
        _cache[22] || (_cache[22] = _createStaticVNode("<div class=\"reactivation-inputs__row _in-develop\" data-v-ddc4e916><span class=\"text-small\" data-v-ddc4e916>с</span><div class=\"reactivation-inputs__col\" data-v-ddc4e916><input type=\"time\" class=\"default-input\" data-v-ddc4e916><span class=\"text-small\" data-v-ddc4e916>по</span><input type=\"time\" class=\"default-input\" data-v-ddc4e916></div><div class=\"text-small reactivation-inputs__sub\" data-v-ddc4e916> Интервал в который разрешена рассылка (В разработке) </div></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[29] || (_cache[29] = _createElementVNode("div", { class: "reactivation-form__title" }, "Считать достижением цели", -1)),
      _cache[30] || (_cache[30] = _createElementVNode("div", { class: "reactivation-form__subtitle text-small" }, " Отразится в отчете ", -1)),
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "fw-500 fs-12px mb-20px" }, " Клиент записался / пришёл / оплатил ", -1)),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", {
          class: _normalizeClass(["reactivation-inputs__row in-develop", { '_in-develop': finishAfterNextMailing.value }])
        }, [
          _cache[25] || (_cache[25] = _createElementVNode("span", { class: "text-small" }, "Отслеживать в течении", -1)),
          _createElementVNode("div", _hoisted_20, [
            _withDirectives(_createElementVNode("input", {
              class: "default-input _days",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((trackingDays).value = $event)),
              onInput: validateTrackingDays,
              onBlur: validateTrackingDaysBlur
            }, null, 544), [
              [_vModelText, trackingDays.value]
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("span", { class: "text-small" }, "дней с момента старта Реактивации ", -1))
          ])
        ], 2),
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "reactivation-inputs__row" }, [
          _createElementVNode("div", { class: "text-small reactivation-inputs__or" }, "или")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("label", _hoisted_22, [
          _createVNode(inputCheck, {
            modelValue: finishAfterNextMailing.value,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((finishAfterNextMailing).value = $event))
          }, null, 8, ["modelValue"]),
          _cache[27] || (_cache[27] = _createElementVNode("span", null, "До следующей активности", -1)),
          _cache[28] || (_cache[28] = _createElementVNode("span", { class: "text-small" }, "Вы всегда сможете завершить активность вручную в любое время", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("button", {
        class: "reactivation-btn btn _gray",
        onClick: prevStep
      }, " Назад "),
      _createElementVNode("button", {
        class: "reactivation-btn btn _create",
        onClick: createMessage,
        disabled: currentReactivationClientCount.value === 0
      }, [
        (currentReactivationClientCount.value !== 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Создать текст сообщения"))
          : (_openBlock(), _createElementBlock("span", _hoisted_26, "Рассылка невозможна")),
        _createElementVNode("span", _hoisted_27, [
          _cache[32] || (_cache[32] = _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(currentReactivationClientCount.value), 1)
        ])
      ], 8, _hoisted_24)
    ])
  ], 2))
}
}

})