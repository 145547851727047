<template>
  <pageWithSectionsHeader
    @close="closePage"
    :sectionsColor="headerSectionsBgs"
    class="reactivation-item-page"
    :closeBtnClass="'btn-type1'"
  >
    <template v-slot:top><slot name="top"></slot></template>
    <template v-slot:header1>
      <p class="si-tit" v-html="statusStr"></p>
      <p class="si-tit" v-html="dateTime.date"></p>
      <p class="si-tit" v-html="dateTime.time"></p>
      <p class="fs-12px fw-500" v-html="currentIdStr"></p>
    </template>
    <template v-slot:header2>
      <p class="si-tit">Начальные настройки</p>
      <p class="fs-12px fw-500 mb-15px" v-html="setting1Str"></p>
      <p class="fs-12px fw-500 mb-15px" v-html="setting2Str"></p>
      <p class="fs-12px fw-500" v-html="setting3Str"></p>
    </template>
    <template v-slot:header3>
      <p class="si-row mb-13px">
        <span>Сегмент</span>
        <span class="fw-500" v-html="currentSegmentName"></span>
      </p>
      <p class="si-row mb-13px">
        <span>Всего в рассылке</span>
        <span class="fw-500" v-html="totalClientsStr"></span>
      </p>
      <p class="si-row mb-13px">
        <span>Отслеживание цели</span>
        <span class="fw-500" v-html="timeRemainingFinish"></span>
      </p>
      <p class="si-row mb-13px" v-if="isShowWhatsApp">
        <span>Отслеживание цели для </span>
        <span class="fw-500" v-html="totalClientsStr"></span>
      </p>
      <p class="si-row" v-if="isShowWhatsApp">
        <span>Окончание цели через</span>
        <span class="fw-500" v-html="timeRemainingFinishMailing"></span>
      </p>
    </template>

    <template v-slot:btns>
      <button
        class="btn _yellow"
        @click="isConfirmStopWatchingShow = true"
        v-if="!isReactivationStopped && false"
      >
        Завершить досрочно
      </button>
      <button class="btn _red" v-if="isShowWhatsApp">
        Остановить рассылку
      </button>
      <button class="btn _blue" v-if="isShowWhatsApp">
        Возобновить рассылку
      </button>
      <span v-if="!isShowWhatsApp"></span>
    </template>

    <template v-slot:content>
      <div class="reactivation-text">
        <h2 class="reactivation-text__title tit-h4 fw-600">Текст сообщения</h2>
        <pre v-if="textStr" class="fw-500 fs-12px reactivation-text__message"
          >{{ textStr }}
        </pre>
      </div>

      <paginationBlock
        :class="{ '--loading': loadingClientByCurrentReactivation }"
        @getPage="(pageNumber) => getPage(pageNumber)"
        :totalItems="totalClient"
        :itemsPerPage="25"
      >
        <template v-slot:elements>
          <zebraTable
            :headerElements="tableHeaderWithoutWa"
            :elements="currentClientsPage"
            :gridColumns="headerGridColWithoutWa"
            :elementsOrder="elementsOrder"
            :firstColHandleClick="true"
            @fisrtElementWasClicked="(client) => goToClient(client)"
            :titles="['Открыть карточку клиента']"
          ></zebraTable>
        </template>
      </paginationBlock>
    </template>
  </pageWithSectionsHeader>

  <confirmAction
    @cancelConfirm="isConfirmStopWatchingShow = false"
    v-if="isConfirmStopWatchingShow"
    :onConfirm="stopWatching"
    confirmationString="завершить"
    infoMessage="Вы завершаете реактивацию досрочно, <br> при этом будет остановлено отслеживание целей. <br> Отменить это дейсвие невозможно!"
  ></confirmAction>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import pageWithSectionsHeader from "@/components/UI/bloks/pageWithSectionsHeader.vue";
import zebraTable from "@/components/UI/bloks/zebraTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import { computed, onMounted, defineEmits, ref } from "vue";
import getSegmentByIndex from "@/functions/getSegmentByIndex";

import confirmAction from "@/components/UI/bloks/confirmAction.vue";
import goToClientPageYclients from "@/functions/goToClientPageYclients";

const headerSectionsBgs = [
  "rgba(109, 207, 27, 0.25)",
  "rgba(205, 247, 255, 1)",
  "rgba(254, 228, 171, 1)",
];

const headerGridColWithWa = "140px 150px repeat(8, minmax(0, 1fr))";
const headerGridColWithoutWa = "140px 150px repeat(5, minmax(0, 1fr))";

const tableHeaderWithWa = [
  "Номер",
  "Имя",
  "Отправлено",
  "Доставлено",
  "Прочитано",
  "Записался",
  "Пришел",
  "Оплатил",
  "Средний чек",
  "Выручка",
];
const tableHeaderWithoutWa = [
  "Номер",
  "Имя",
  "Записался",
  "Пришел",
  "Оплатил",
  "Средний чек",
  "Выручка",
];

const elementsOrder = [
  "phone",
  "name",
  { value: "record_status", func: (value) => statusOrder1(value) },
  { value: "record_status", func: (value) => statusOrder2(value) },
  { value: "payment_status", func: (value) => statusOrderPayment(value) },
  { value: "average_check", func: (value) => getModifySum(value) },
  { value: "amount", func: (value) => getModifySum(value) },
];

function statusOrder1(statusOrder) {
  return statusOrder > -2;
}
function statusOrder2(statusOrder) {
  return statusOrder >= 1;
}
function statusOrderPayment(statusPayment) {
  return !!statusPayment;
}
function getModifySum(number) {
  return number.toLocaleString() + " ₽";
}

const props = defineProps({
  activation: Object,
});
/* eslint-disable-next-line */
const emit = defineEmits();

const store = useStore();

function goToClient(client) {
  const salonId = store.state.salon_id;
  const number = client.phone;

  goToClientPageYclients(salonId, number);
}

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

const currentSegmentName = computed(() => {
  const modifySegments = store.state.segments.modifySegments;
  const segmentIndex = props.activation.segment_id;
  return getSegmentByIndex(modifySegments, segmentIndex).name;
});

const loadingClientByCurrentReactivation = computed(
  () => store.state.reactivation.loadingClientByCurrentReactivation
);

const isConfirmStopWatchingShow = ref(false);

function stopWatching() {
  store.dispatch("reactivation/stopWatchingReactivation", props.activation.id);
  emit("close");
}
function closePage() {
  emit("close");
}

const currentClientsPage = computed(
  () => store.state.reactivation.clientByCurrentReactivation
);

const isReactivationStopped = computed(
  () => props.activation.goal.status_id < 1
);

const statusStr = computed(() => props.activation.goal.description);
const dateTime = computed(() => {
  const dateTimeString = props.activation.start_date;
  const [datePart, timePart] = dateTimeString.split(" ");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  return {
    date: `${day}.${month}.${year}`,
    time: `${hours}:${minutes}`,
  };
});

function getPluralForm(number, forms) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1];
  }
  if (n1 === 1) {
    return forms[0];
  }
  return forms[2];
}

const setting1Str = computed(() =>
  props.activation.goal.ignore_stop
    ? "Отправляем при согласии"
    : "Для всех клиентов сегмента"
);
const setting2Str = computed(() =>
  props.activation.channel[0] === "SMS"
    ? "Всем отправить по WhatsApp"
    : `Всем отправить по ${props.activation.channel[0]}`
);
const setting3Str = computed(() => "Цель - Клиент пришёл и оплатил");

const totalClient = computed(() => props.activation.total_clients);

const totalClientsStr = computed(
  () =>
    totalClient.value +
    " " +
    getPluralForm(totalClient, ["клиент", "клиента", "клиентов"])
);

const textStr = computed(() => props.activation.text || null);

const timeRemainingFinish = computed(() =>
  getTimeRemaining(props.activation.goal.finish_date)
);
const timeRemainingFinishMailing = computed(() =>
  getTimeRemaining(props.activation.goal.finish_mailing_date)
);

const currentIdStr = computed(() => {
  return `ID реактивации - #${props.activation.id}`;
});

function getTimeRemaining(targetDateStr) {
  const targetDate = new Date(targetDateStr);
  const currentDate = new Date();

  const diffInMilliseconds = targetDate - currentDate;

  if (diffInMilliseconds <= 0) {
    return "Дата уже прошла";
  }

  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const days = Math.floor(diffInHours / 24);
  const hours = diffInHours % 24;

  const dayForms = ["день", "дня", "дней"];
  const hourForms = ["час", "часа", "часов"];

  const dayString = `${days} ${getPluralForm(days, dayForms)}`;
  const hourString = `${hours} ${getPluralForm(hours, hourForms)}`;

  return `${dayString} и ${hourString}`;
}

function getPage(pageNumber) {
  store.dispatch("reactivation/getReactivationClientsByPage", {
    page: pageNumber,
    reactivationId: props.activation.id,
  });
}
</script>

<style lang="scss" scoped>
.reactivation-text {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-left: 5px;
  &__title {
    margin-bottom: 10px;
  }

&__message {
    max-width: 500px;
    text-wrap: wrap;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 25px 25px 25px 5px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    font-family: 'Comic Sans MS', cursive, sans-serif;
    color: #333;
    transition: background-color 0.3s;
}

&__message:hover {
    background-color: #e6f7ff;
}
}
</style>
