<template>
  <div class="confirm-reactivation">
    <div class="confirm-reactivation__row">
      Мы не несем ответственности за возможную блокировку номера или другие
      санкции, связанные с отправкой сообщений. Пожалуйста, убедитесь, что вы
      ознакомлены с правилами и рекомендациями для безопасной отправки сообщений
      через мессенджеры.
    </div>
    <div class="confirm-reactivation__row" v-if="!isReactivationIncludeNotification">
      Будет создана рективация, будут отслеживаться цели, при этом уведомления не будут
    </div>
    <div class="confirm-reactivation__row">
      Нажимая на кнопку, вы подтверждаете, что понимаете риски и принимаете их.
    </div>

    <div class="confirm-reactivation__btns">
      <button class="btn _gray" @click="prevStep">Назад</button>
      <button class="btn" @click="createReactivation">
        Я понимаю риски и хочу продолжить
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";
import router from '@/router'

const store = useStore();

const emit = defineEmits(['prev']);

const isReactivationIncludeNotification = computed(
  () => store.state.reactivation.isReactivationIncludeNotification
);

function prevStep() {
  if (isReactivationIncludeNotification.value) {
    store.state.reactivation.reactivationCreateStep = 1;
  } else {
    emit("prev");

    store.state.reactivation.reactivationCreateStep = 0;

    router.push("/");

    window.scrollTo({
      top: 0,
    });
  }
}

function createReactivation() {
  store.dispatch("reactivation/createReactivation");
}
</script>

<style lang="scss" scoped>
.confirm-reactivation {
  border: 1px solid #71757f;
  border-radius: 24px;
  padding: 29px;
  max-width: 600px;

  font-weight: 500;
  font-size: 16px;
  line-height: 171%;
  color: #000;
  &__row {
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    justify-content: space-between;
  }
}
</style>
