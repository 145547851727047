<template>
  <div class="tariffs">
    <div class="tariffs__status">
      <tarifStatus v-if="currentTarif"></tarifStatus>
    </div>

    <div class="tariffs__banner" v-if="allTariffs && showTariffBanner">
      На данный момент у вас активирован тестовый тариф, в котором нельзя
      создавать реактивации и скачивать клиентов. <br />
      Для доступа ко всем функциям, пожалуйста, приобретите полный тариф.
    </div>

    <div class="tariffs-list" v-if="allTariffs">
      <tarifCard
        v-for="tarif in allTariffs"
        :key="tarif"
        :tarif="tarif"
        :type="'full'"
      ></tarifCard>
    </div>
    <div v-else-if="getTariffsMethodErr" class="fw-500">Ошбика: {{getTariffsMethodErr}}!</div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import tarifCard from "@/components/tariffs/tarifCard.vue";
import tarifStatus from "@/components/tariffs/tarifStatus.vue";
import { computed, onUnmounted } from "vue";

const store = useStore();

const getTariffsMethodErr = computed((() => store.state.tarif.getTariffsMethodErr))

const allTariffs = computed(() => store.state.tarif.allTariffs);

const currentTarif = computed(() => store.state.tarif.currentTarif);

const showTariffBanner = computed({
  get: () => store.state.tarif.showTariffBanner,
  set: (newValue) => store.commit("tarif/setTariffBanner", newValue),
});

onUnmounted(() => {
  showTariffBanner.value = false;
});
</script>

<style lang="scss" scoped>
.tariffs {
  &__title {
    margin-bottom: 30px;
    font-weight: 500;
  }

  &__status {
    margin-bottom: 25px;
  }
  &__banner {
    background-color: #f0f4ff;
    border: 1px solid #d1d9e6;
    border-radius: 12px;
    padding: 15px;
    font-size: 18px;
    line-height: 1.6;
    color: #2c3e50;
    margin-bottom: 25px;
    max-width: 1200px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    font-weight: 500;
  }
}
.tariffs-list {
  display: flex;
  gap: 83px;
}
</style>
