import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const clientsModule: Module<any, any> = {
  namespaced: true,
  state: {
    currentClientPage: {},
    clientPageLoading: false,

    currentSearchClientPhone: "",
    currentSearchClient: null,
    currentSearchLoading: false,

    recomedationPageClientsShow: false,
    defaultRecomendationPageClients: 1,
    scrollToClientId: '',
  },
  getters: {},
  mutations: {
    clearClientPages(state) {
      state.currentClientPage = {};
    },
  },
  actions: {
    async getClientsForSegmentByPage(
      context,
      payload: { segmentIndex: string; page: number }
    ) {
      context.state.clientPageLoading = true;
      return await axios({
        url: "https://rfm.botzavod.com/api/company-segment-clients",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
          segment_id: `${payload.segmentIndex}`,
          page: payload.page.toString(),
        },
        method: "GET",
      }).then((res: AxiosResponse) => {
        context.state.clientPageLoading = false;
        context.state.currentClientPage = res.data;

        if (context.state.currentClientPage.segment_clients) {
          context.state.currentClientPage.segment_clients.forEach(
            (client: any) => {
              client.selected = false;
            }
          );
        }
      });
    },
    async searchClientByPhone(context) {
      context.state.currentSearchLoading = true;
      return await axios({
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        url: "https://rfm.botzavod.com/api/search-client-segment",
        params: {
          salon_id: context.rootState.salon_id,
          phone: context.state.currentSearchClientPhone,
        },
      })
        .then((data) => {
          console.log(data);
          context.state.currentSearchClient = data.data;
          context.state.currentSearchLoading = false;
        })
        .catch((err) => {
          console.log(err);
          context.state.currentSearchClient = err.response.data;
          context.state.currentSearchLoading = false;
        });
    },
  },
};

export default clientsModule;
