import { ITarifState } from "@/types/tarif/tarifTypes";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";


const tarifModule: Module<ITarifState, any> = {
  namespaced: true,
  state: {
    currentTarif: null,
    allTariffs: null,
    showTariffBanner: false,
    getTariffsMethodErr: null,
  },
  getters: {},
  mutations: {
    setTariffBanner(state:ITarifState, isShowBanner:boolean) {
      state.showTariffBanner = isShowBanner
    }
  },
  actions: {
    async getAllTariffs(context) {
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-available-company-tariffs",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id
        }
      }).then((res:AxiosResponse) => {
        console.log(res)
        context.state.allTariffs = res.data.tariffs
      }).catch((err) => {
        console.log(err)
        const description = err.response.data.description
        if (description) {
          context.state.getTariffsMethodErr = description
        }
      })
    },
    async getCurrentTarif(context) {
      return await axios({
        method: 'GET',
        url: 'https://rfm.botzavod.com/api/company-license',
        params: {
          salon_id: context.rootState.salon_id
        },
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`
        }
      }).then((res:AxiosResponse) => {
        context.state.currentTarif = res.data.company_license

        return res.data.company_license
      })
    }
  },
};

export default tarifModule;
