<template>
  <div class="reactivation-create">
    <div class="reactivation-create__title">
      {{ reactivationDatetime }}Сегмент {{ activeSegment.name }} |
      {{ currentReactivationClientCount }}
    </div>

    <reactivationCreateForm
      v-show="reactivationCreateStep === 0"
      @prev="isCloseSettingsByUnmounted = false"
    ></reactivationCreateForm>
    <reactivationTemplate
      v-show="reactivationCreateStep === 1"
    ></reactivationTemplate>
    <confirmReactivation
      v-show="reactivationCreateStep === 2"
      @prev="isCloseSettingsByUnmounted = false"
    ></confirmReactivation>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import reactivationCreateForm from "@/components/reactivation/reactivationCreateForm.vue";
import reactivationTemplate from "@/components/reactivation/reactivationTemplate.vue";
import confirmReactivation from "@/components/reactivation/confirmReactivation.vue";
import getDeclension from "@/assets/getDeclension";
import { computed, onUnmounted, ref, watch } from "vue";

const store = useStore();

const reactivationCreateStep = computed(
  () => store.state.reactivation.reactivationCreateStep
);

let isCloseSettingsByUnmounted = ref(true)

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const reactivationDatetime = computed(() => {
  const datetime = store.getters["reactivation/getNewReactivationDatetime"];
  if (datetime) {
    return datetime + " | ";
  } else {
    return "";
  }
});

const createReactivationFromData = computed(
  () => store.state.reactivation.createReactivationFromData
);

watch(reactivationCreateStep, () => window.scrollTo(0,0))

watch(
  (createReactivationFromData) => {
    store.dispatch("reactivation/getReactivationCount");
  },
  { deep: true }
);

const currentReactivationClientCount = computed(() => {
  const count = store.state.reactivation.currentReactivationClientCount;
  return count + ' ' + getDeclension(count, "клиент", "клиента", "клиентов");
});

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

onUnmounted(() => {
  if (isCloseSettingsByUnmounted.value) {
    store.state.reactivation.reactivationCreateStep = 0;
    store.state.segments.showSegmentSettings = false;
  }

  window.scrollTo(0, 0);
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

$grid-col: 1.8fr 5fr 1fr;
.reactivation-create {
  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 28px;
    margin-bottom: 16px;
  }
}

.default-input._days {
  max-width: 70px;
}

.mb-28px {
  margin-bottom: 28px;
}
</style>
