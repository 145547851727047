import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "recomendation-block__index" }
const _hoisted_2 = { class: "base-segment__number" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "recomendation-block__name" }
const _hoisted_5 = { class: "fw-500" }
const _hoisted_6 = { class: "fw-500" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "fw-500" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["src"]

import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import segmentClients from "@/components/main-page/segments/segmentClients.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

import pageWithSectionsHeader from "@/components/UI/bloks/pageWithSectionsHeader.vue";

import goToSegmentInYclients from "@/functions/goToSegmentInYclients";

type typeProps = {
  segment: TypeSegment;
};
/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'segmentRecomendation',
  props: {
    segment: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const isCurrentTarifTest = computed(
  () => store.state.tarif.currentTarif.test_tariff
);

const currentIndex = computed(() => {
  const index = currentSegment.value.index;
  if (typeof index === "number") {
    if (index < 10) {
      return "0" + index;
    } else {
      return index;
    }
  } else {
    return index;
  }
});

function goToClientsInYclients() {
  const recenciesObj = store.getters["segments/getRecencies"];
  const url = goToSegmentInYclients(
    store.state.salon_id,
    props.segment,
    recenciesObj
  );

  console.log(url);
}

function downloadExel() {
  store.commit("tarif/setTariffBanner", true);
  router.push("/tariffs");
}
function goToCreateReactivationWithoutNots() {
  store.state.reactivation.reactivationCreateStep = 2;
  store.state.reactivation.isReactivationIncludeNotification = false
  goReactivation()
}

const recomednationPageClientsShow = computed({
  get: () => store.state.segments.clients.recomednationPageClientsShow,
  set: (newVal) =>
    (store.state.segments.clients.recomednationPageClientsShow = newVal),
});

const total_clients = computed(() => +props.segment.total_clients);

const totalClientsStr = computed(() => total_clients.value.toLocaleString());
const totalClientsPercentStr = computed(
  () => currentSegment.value.total_clients_percent + "%"
);

const currentSegmentBg = computed(() => currentSegment.value.background_color);
const avarageCheckStr = computed(() => {
  const result = Math.trunc(currentSegment.value.average_check);

  if (isNaN(result)) {
    return null;
  } else {
    return result.toLocaleString() + " ₽";
  }
});
const visitsCountStr = computed(() => currentSegment.value.visits || null);

const company_rfm = computed(() => store.state.segments.company_rfm);

function hideSegments() {
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

const downloadUrl = computed(() => {
  const salonId = store.state.salon_id;
  const segmentId = currentSegment.value.index;

  const concatenatedString = `${salonId}${segmentId}`;
  const base64Encoded = btoa(concatenatedString);

  console.log(salonId, segmentId, base64Encoded);

  const url = `https://rfm.botzavod.com/api/export-company-segment-clients?salon_id=${salonId}&segment_id=${segmentId}&key=${base64Encoded}`;

  return url;
});

function getRecency(recency) {
  const number = +recency;

  let result = 0;
  if (number === 0) {
    return result;
  }
  if (number === 51.5) {
    result = +company_rfm.value.r1_level;
  }

  if (number === 90) {
    result = +company_rfm.value.r2_level;
  }
  if (number === 125) {
    result = +company_rfm.value.r3_level;
  }
  if (number === 160) {
    result = +company_rfm.value.r4_level;
  }

  return result.toFixed();
}

const recencyStr = computed(() => {
  return `от ${getRecency(currentSegment.value.min_recency)} до
          ${getRecency(currentSegment.value.max_recency)} дней`;
});

function goReactivation() {
  isGoingReactivation.value = true;

  router.push("/reactivation");
  window.scrollTo(0,0)
  
}
const isGoingReactivation = ref(false);

onUnmounted(() => {
  store.state.segments.clients.recomednationPageClientsShow = false;
  if (isGoingReactivation.value) {
    store.state.segments.showSegmentSettings = true;
  } else {
    store.state.segments.showSegmentSettings = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(pageWithSectionsHeader, {
    onClose: hideSegments,
    sectionsColor: [currentSegmentBg.value, currentSegmentBg.value, currentSegmentBg.value],
    class: "segment-recomendation",
    closeBtnClass: 'btn _gray w-100'
  }, {
    header1: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(currentIndex.value), 1),
        _createElementVNode("img", {
          class: "base-segment__smile",
          src: 
            require('@/assets/images/segments-smiles/' +
              currentSegment.value.smileAnimation)
          ,
          alt: ":)"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(currentSegment.value.name), 1)
    ]),
    header2: _withCtx(() => [
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "si-tit" }, "Статистика по сегменту*", -1)),
      _createElementVNode("p", null, [
        _cache[1] || (_cache[1] = _createTextVNode(" Клиентов - ")),
        _createElementVNode("span", _hoisted_5, _toDisplayString(totalClientsStr.value), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createTextVNode(" От общего числа - ")),
        _createElementVNode("span", _hoisted_6, _toDisplayString(totalClientsPercentStr.value), 1)
      ]),
      (avarageCheckStr.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
            _cache[3] || (_cache[3] = _createTextVNode(" Средний чек - ")),
            _createElementVNode("span", _hoisted_8, _toDisplayString(avarageCheckStr.value), 1)
          ]))
        : _createCommentVNode("", true),
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "si-ps" }, "*На сейчас", -1))
    ]),
    header3: _withCtx(() => [
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "si-tit" }, "Параметры сегмента*", -1)),
      (currentSegment.value.min_recency >= 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
            _cache[6] || (_cache[6] = _createTextVNode(" Давность визитов - ")),
            (currentSegment.value.min_recency >= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(recencyStr.value), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (currentSegment.value.visits)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
            _cache[7] || (_cache[7] = _createTextVNode(" Количество визитов - ")),
            _createElementVNode("span", null, _toDisplayString(visitsCountStr.value), 1)
          ]))
        : _createCommentVNode("", true),
      _cache[9] || (_cache[9] = _createElementVNode("p", { class: "si-ps" }, "*Рассчитывается автоматически при помощи алгоритма ИИ", -1))
    ]),
    btns: _withCtx(() => [
      (currentSegment.value.total_clients)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (recomednationPageClientsShow.value = !recomednationPageClientsShow.value)),
            class: _normalizeClass([recomednationPageClientsShow.value ? '_blue' : '_green', "btn"])
          }, " Показать " + _toDisplayString(recomednationPageClientsShow.value ? "рекомендации" : "клиентов"), 3))
        : _createCommentVNode("", true),
      (currentSegment.value.total_clients)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn",
            style: {"border-top-left-radius":"0"},
            onClick: goReactivation
          }, " Реактивация "))
        : _createCommentVNode("", true),
      (
          recomednationPageClientsShow.value && downloadUrl.value && !isCurrentTarifTest.value
        )
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            class: "btn _green",
            href: downloadUrl.value
          }, " Скачать клиентов (.xlsx) ", 8, _hoisted_12))
        : (recomednationPageClientsShow.value && isCurrentTarifTest.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "btn _green",
              onClick: downloadExel
            }, " Скачать клиентов (.xlsx) "))
          : _createCommentVNode("", true),
      (recomednationPageClientsShow.value && false)
        ? (_openBlock(), _createElementBlock("button", {
            key: 4,
            class: "btn _blue1",
            onClick: goToClientsInYclients
          }, " Клиенты сегмента в Yclients "))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      _withDirectives(_createElementVNode("iframe", {
        ref: "iframeRef",
        src: `https://b24-7zji69.bitrix24site.ru/${currentSegment.value.key}/`,
        class: "segment-recomendation__iframe",
        frameborder: "0"
      }, null, 8, _hoisted_13), [
        [_vShow, !recomednationPageClientsShow.value]
      ]),
      (recomednationPageClientsShow.value)
        ? (_openBlock(), _createBlock(segmentClients, {
            key: 0,
            class: "segment-recomendation__clients"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["sectionsColor"]))
}
}

})