<template>
  <div class="page-sections-header">
    <div class="page-sections-header__top">
      <slot name="top"></slot>
      <button :class="closeBtnClass" @click="$emit('close')">Закрыть без изменений</button>
    </div>
    <header class="sections-header">
      <div class="sections-info">
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[0]}`"
        >
          <slot name="header1"></slot>
        </div>
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[1]}`"
        >
          <slot name="header2"></slot>
        </div>
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[2]}`"
        >
          <slot name="header3"></slot>
        </div>
      </div>
    </header>

    <div class="page-sections-header__btns">
      <slot name="btns"></slot>
    </div>

    <div class="page-sections-header__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
type typeProps = {
  sectionsColor: Array<string>;
  closeBtnClass: string
};

const props = withDefaults(defineProps<typeProps>(), {
  sectionsColor: () => ["#fff", "#fff", "#fff"],
});
</script>

<style scoped lang="scss">
$widthPage: 1050px;
$contentWidth: 1050px;
.page-sections-header {
  max-width: $widthPage;
  &__top {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    button.btn-type1 {
      flex-grow: 1;
      padding: 0;
    }
  }
  &__content {
    width: $contentWidth;
  }

  &__btns {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: center;
    column-gap: 40px;
    row-gap: 20px;
  }
}
.sections-header {
  display: flex;
  grid-gap: 19px;
}
.sections-info {
  display: inline-flex;
  gap: 7px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 19px;
  justify-self: start;
  width: 100%;

  &__block {
    padding: 19px 30px;
    min-height: 138px;
    position: relative;
    &:nth-child(1) {
      border-radius: 15px 0 0 15px;
      width: 25%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      border-radius: 0 15px 15px 0;
      min-width: 400px;
    }
  }
}
</style>

<style lang="scss">
.sections-info {
  &__block {
    p {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p.si-tit {
      margin-bottom: 14px;
      font-weight: 500;
    }
    p.si-ps {
      position: absolute;
      bottom: 0px;
      right: 10px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 10px;
      letter-spacing: 0.3px;
    }
    p.si-row {
      display: flex;
      justify-content: space-between;

      span:last-child {
        flex-grow: 1;
        max-width: 150px;
      }
    }
  }
}

.sections-header {
  .btn {
    &:nth-child(1) {
      align-self: start;
    }
    &:nth-child(2) {
      align-self: start;
    }
    &:nth-child(3) {
      align-self: end;
    }
    &:nth-child(4) {
      align-self: end;
    }
  }
}
</style>
